<ng-container *ngIf="navMenuItems && navMenuItems.length > 0">
  <mat-sidenav-container class="container" autosize>
    <mat-sidenav class="flex-item" mode="side" disableClose="true" opened="true">
      <div style="display: flex; justify-content: space-between">
        <div class="menu-icon">
          <mat-icon [matTooltip]="(isExpanded() ? 'close_menu' : 'expand_menu') | intl" (click)="toggleExpanded()" aria-label="Menu">{{
            expandedIcon
          }}</mat-icon>
        </div>
        @if (!menuAlwaysExpanded && isExpanded()) {
        <mat-icon
          class="menu-icon"
          style="cursor: pointer; font-size: 16px; width: 16px; height: 16px"
          [matTooltip]="(storageSettings()?.isExpanded ? 'save_menu_opened' : 'save_menu_closed') | intl"
          (click)="toggleMenuItemsExpanded()"
        >
          {{ storageSettings()?.isExpanded ? 'open_in_full' : 'close_fullscreen' }}
        </mat-icon>
        }
      </div>
      <mat-nav-list #leftElement [ngClass]="menuAlwaysExpanded === true ? 'resizable' : isExpanded() ? 'resizable' : 'collapsed'">
        <rcg-nav-menu
          [showZeroCount]="showZeroCount"
          [navMenuItems]="navMenuItems"
          [routable]="routable"
          [childMenuItemsExpanded]="childMenuItemsExpanded"
          [favorites]="favorites"
          [isExpanded]="isExpanded()"
          [favoritesMenuPosition]="favoritesMenuPosition"
          (selectedFavorite)="selectedFavorite.emit($event)"
          (navMenuItemClicked)="onNavMenuItemClicked($event)"
        ></rcg-nav-menu>
      </mat-nav-list>
      @if (leftElementHTMLElement && rightElementHTMLElement && isExpanded()) {
      <rcg-resizer
        [resizableWidth]="leftElementHTMLElement.nativeElement.clientWidth"
        [minWidth]="56"
        [maxWidth]="400"
        [hostElement]="el.nativeElement"
        [widthVariableName]="'--menu-width'"
        [role]="'menu'"
      ></rcg-resizer>
      }
    </mat-sidenav>
    <mat-sidenav-content class="flex-item" #rightElement>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
