import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { DataConnectorConfig } from '@npm-libs/ng-templater';
import { GqlInput, QueryInput } from '@rcg/core';
import { IntlModule } from '@rcg/intl';
import { DataGridViewComponent, DataGridViewConfig } from '../../templater/data-grid';
import { DataCounterComponent } from '../data-counter/containers/data-counter/data-counter.component';
import { RcgGridComponent } from '../grid/grid.component';

export interface GridTabsServerConfig {
  gridId: number;
  runtimeData: Record<string, unknown>;
  icon: string;
  label: string;
  gqlCounter?: GqlInput | QueryInput;
}

export interface GridClientTabsConfig {
  icon: string;
  label: string;
  config: DataGridViewConfig;
  dataConnectorConfigs: DataConnectorConfig[];
  gqlCounter?: GqlInput | QueryInput;
}

@Component({
  selector: 'rcg-grid-tabs',
  standalone: true,
  imports: [CommonModule, MatTabsModule, MatIconModule, RcgGridComponent, IntlModule, DataGridViewComponent, DataCounterComponent],
  template: `
    <div style="display: flex; flex-direction: column; flex: 1; height: 100%">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" style="flex: 1">
        @if(tabs() && tabs()!.length>0){ @for (tab of tabs(); track $index) {
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="tab-icon" style="margin-right: 8px">{{ tab.icon }}</mat-icon>
            {{ tab.label | intl }}
            @if(tab.gqlCounter){
            <rcg-data-counter style="margin-left: 4px" [gqlInput]="tab.gqlCounter" [displayType]="'text'" textPrefix="(" textSuffix=")">
            </rcg-data-counter>
            }
          </ng-template>
          <ng-template matTabContent>
            @if (isServerTab(tab)) {
            <rcg-grid [gridId]="tab.gridId" [runtimeData]="tab.runtimeData" style="height: 100%"></rcg-grid>
            } @else if(isClientTab(tab)) {
            <rcg-templater-view--data-grid [config]="tab.config" [dataConnectorConfigs]="tab.dataConnectorConfigs">
            </rcg-templater-view--data-grid>
            }
          </ng-template>
        </mat-tab>
        } }
      </mat-tab-group>
    </div>
  `,
  styles: [``],
})
export class GridTabsComponent {
  tabs = input<(GridClientTabsConfig | GridTabsServerConfig)[]>();

  protected isServerTab(tab: GridClientTabsConfig | GridTabsServerConfig): tab is GridTabsServerConfig {
    return 'gridId' in tab;
  }

  protected isClientTab(tab: GridClientTabsConfig | GridTabsServerConfig): tab is GridClientTabsConfig {
    return 'config' in tab && 'dataConnectorConfigs' in tab;
  }
}
