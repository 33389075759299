import { gql } from 'apollo-angular';

export const organizationQuery = gql`
  query organizationQuery($id: bigint!) {
    data: global_organizations_by_pk(id: $id) {
      id
      short_name
      long_name
      street
      postal_name
      postal_number
      house_number
      country {
        name
      }
    }
  }
`;

export const customerSummaryQuery = gql`
  query sub($functionName: String!, $parameters: jsonb) {
    data: sqlFunctionQuery(functionName: $functionName, parameters: $parameters) {
      data
    }
  }
`;
