<mat-card appearance="outlined" class="container">
  @if(dataCounterService.count(); as count){
  <span>{{ count?.value ?? 0 | secondsToTime }}</span>
  <div class="right-content">
    @if(search.observed){
    <div class="search-container">
      <input class="search-field" matInput [formControl]="searchControl" [placeholder]="(searchPlaceholder() ?? '') + '...'" />
      @if(searchControl.value){
      <mat-icon class="clear-icon" matSuffix (click)="clearSearch()">clear</mat-icon>
      } @else {
      <mat-icon matSuffix class="search-icon">search</mat-icon>
      }
    </div>
    } @if(filtersSettings?.id){
    <rcg-filters [count]="dataCount" [filterSetting]="filtersSettings" (filter)="onFilterChange($event)"> </rcg-filters>
    } @if(actions && actions.length > 0){
    <rcg-actions [actions]="actions" [actionSize]="'22px'" [actionViewType]="'icon'" (actionClicked)="action.emit($event)"></rcg-actions>
    }
    <mat-icon>{{ icon ?? 'functions' }}</mat-icon>
  </div>
  }
</mat-card>
