import { QueryInput } from '@rcg/core';
import { RcgCardView, RcgMasterDetailView, RcgQueryListView, RcgTabsView, RcgView } from '../../../models';

export const ncrSettings = (customerId: number) => {
  return [
    {
      type: 'tabs',
      name: 'tabs',
      align: 'start',
      strech: false,
      tabs: [
        {
          type: 'card',
          name: 'customerSummary',
          translation: 'summary',
          description: 'summary',
          icon: 'info',
          view: {
            type: 'card',
            name: 'customerSummary',
          },
        } as RcgCardView,
        {
          icon: 'event',
          translation: 'activities',
          description: 'activities',
          view: {
            type: 'master-detail',
            name: 'activities',
            list: {
              searchable: true,
              itemSize: 90,
              type: 'query-list',
              name: 'activities',
              componentName: 'salesListItem',
              query: {
                type: 'hasura-subscription',
                alias: 'data',
                countAll: {
                  enabled: true,
                },
                count: true,
                fields: [
                  'id',
                  'created_at',
                  'updated_at',
                  'short_description',
                  {
                    status: ['id', 'description', 'color'],
                  },
                  {
                    user: ['id', 'full_name'],
                  },
                  {
                    customer: ['id', 'short_name'],
                  },
                  {
                    contact: ['id', 'full_name'],
                  },
                ],
                operation: 'sales_search_activities',
                variables: {
                  args: {
                    type: 'sales_search_activities_args!',
                    value: {
                      p_search: '$search',
                    },
                  },
                  limit: {
                    value: 15,
                  },
                  where: {
                    type: 'sales_activities_bool_exp',
                    value: [
                      {
                        condition: 'and',
                        predicates: [
                          {
                            field: 'tenant_id',
                            value: '$tenantId',
                            operator: 'eq',
                          },
                          {
                            field: 'customer_id',
                            value: customerId,
                            operator: 'eq',
                          },
                        ],
                      },
                    ],
                  },
                  offset: {
                    value: 0,
                  },
                  order_by: {
                    type: '[sales_activities_order_by!]',
                    value: [
                      {
                        field: 'updated_at',
                        direction: 'descending',
                      },
                    ],
                  },
                },
              } as QueryInput,
            } as RcgQueryListView,
            detail: {
              type: 'form',
              name: 'form',
              formId: 908,
              formMode: 'update',
            },
          } as RcgMasterDetailView,
        },
        {
          translation: 'opportunities',
          description: 'opportunities',
          icon: 'store',
          view: {
            type: 'master-detail',
            name: 'opportunities',
            list: {
              type: 'query-list',
              name: 'opportunities',
              searchable: true,
              itemSize: 100,
              componentName: 'salesListItem',
              query: {
                type: 'hasura-query',
                alias: 'data',
                countAll: {
                  enabled: true,
                },
                count: true,
                fields: [
                  'id',
                  'form_id',
                  'created_at',
                  'updated_at',
                  'short_description',
                  {
                    status: ['id', 'description', 'color'],
                  },
                  {
                    opportunity_resource: ['description_ml'],
                  },
                  {
                    user: ['id', 'full_name'],
                  },
                  {
                    customer: ['id', 'short_name'],
                  },
                  {
                    organization: ['id', 'short_name'],
                  },
                  {
                    contact: ['id', 'full_name'],
                  },
                ],
                operation: 'sales_search_opportunity',
                variables: {
                  args: {
                    type: 'sales_search_opportunity_args!',
                    value: {
                      search: '$search',
                    },
                  },
                  limit: {
                    value: 15,
                  },
                  where: {
                    type: 'sales_opportunity_bool_exp',
                    value: [
                      {
                        condition: 'and',
                        predicates: [
                          {
                            field: 'tenant_id',
                            value: '$tenantId',
                            operator: 'eq',
                          },
                          {
                            field: 'customer_id',
                            value: customerId,
                            operator: 'eq',
                          },
                        ],
                      },
                    ],
                  },
                  offset: {
                    value: 0,
                  },
                  order_by: {
                    type: '[sales_opportunity_order_by!]',
                    value: [
                      {
                        field: 'updated_at',
                        direction: 'descending',
                      },
                    ],
                  },
                },
              } as QueryInput,
            } as RcgQueryListView,
            detail: {
              type: 'card',
              name: 'salesDetail',
              view: {
                type: 'card',
                name: 'salesDetail',
              },
            } as RcgCardView,
          } as RcgMasterDetailView,
        },
        {
          translation: 'device_pl',
          description: 'Devices',
          icon: 'devices',
          view: {
            type: 'master-detail',
            name: 'devices',
            list: {
              type: 'query-list',
              name: 'deviceListItem',
              componentName: 'deviceListItem',
              searchable: true,
              query: {
                type: 'hasura-query',
                alias: 'data',
                count: true,
                countAll: {
                  enabled: true,
                },
                fields: [
                  'id',
                  'tenant_id',
                  'organization_id',
                  'data',
                  'is_active',
                  'date_of_instalation',
                  'warranty_until',
                  'product_id',
                  'serial_no',
                  'description',
                  'updated_at',
                  {
                    customer: ['id', 'short_name'],
                  },
                  'form_id',
                ],
                operation: 'custom_installed_equipment_search_1',
                variables: {
                  args: {
                    type: 'custom_installed_equipment_search_1_args!',
                    value: {
                      p_search: '$search',
                      p_organization_id: '$organizationId',
                    },
                  },
                  limit: {
                    value: 20,
                  },
                  where: {
                    type: 'custom_installed_equipment_bool_exp',
                    value: [
                      {
                        condition: 'and',
                        predicates: [
                          {
                            field: 'tenant_id',
                            value: '$tenantId',
                            operator: 'eq',
                          },
                          {
                            field: 'customer.id',
                            value: customerId,
                            operator: 'eq',
                          },
                        ],
                      },
                    ],
                  },
                  offset: {
                    value: 0,
                  },
                  order_by: {
                    type: '[custom_installed_equipment_order_by!]',
                    value: [
                      {
                        field: 'updated_at',
                        direction: 'descending',
                      },
                    ],
                  },
                },
              },
            } as RcgQueryListView,
            detail: {
              type: 'form',
              name: 'form',
              formId: 833,
              formMode: 'update',
            },
          } as RcgMasterDetailView,
        },
        {
          translation: 'service_orders',
          description: 'Work Orders',
          icon: 'build',
          view: {
            type: 'master-detail',
            name: 'work_orders',
            list: {
              type: 'query-list',
              name: 'workOrderListItem',
              componentName: 'workOrderListItem',
              searchable: true,
              query: {
                type: 'hasura-query',
                alias: 'data',
                countAll: {
                  enabled: true,
                },
                count: true,
                fields: [
                  'id',
                  'form_id',
                  'created_at',
                  'updated_at',
                  'short_description',
                  'external_number',
                  'data',
                  'subscription_capable',
                  'process_definition_id',
                  'process_definition_matrix_id',
                  'owner_id',
                  'has_communication',
                  'color',
                  'rating',
                  'bg_color',
                  'has_attachments',
                  {
                    project: ['id', 'short_description'],
                  },
                  {
                    owner: ['id', 'full_name', 'username'],
                  },
                  {
                    contact: ['id', 'full_name', 'email'],
                  },
                  {
                    customer: ['id', 'short_name'],
                  },
                  {
                    organization: ['id', 'short_name'],
                  },
                  {
                    status: ['id', 'color', 'description'],
                  },
                  {
                    priority: ['id', 'description', 'icon', 'order_index'],
                  },
                ],
                operation: 'servicedesk_search_helpdesk',
                variables: {
                  args: {
                    type: 'servicedesk_search_helpdesk_args!',
                    value: {
                      org_id: '$organizationId',
                      search: '$search',
                    },
                  },
                  limit: {
                    value: 20,
                  },
                  where: {
                    type: 'servicedesk_helpdesk_bool_exp',
                    value: [
                      {
                        condition: 'and',
                        predicates: [
                          {
                            field: 'tenant_id',
                            value: '$tenantId',
                            operator: 'eq',
                          },
                          {
                            field: 'customer.id',
                            value: customerId,
                            operator: 'eq',
                          },
                        ],
                      },
                    ],
                  },
                  offset: {
                    value: 0,
                  },
                  order_by: {
                    type: '[servicedesk_helpdesk_order_by!]',
                    value: [
                      {
                        field: 'updated_at',
                        direction: 'descending',
                      },
                    ],
                  },
                },
              } as QueryInput,
            } as RcgQueryListView,
            detail: {
              type: 'card',
              name: 'workOrderDetail',
              view: {
                type: 'card',
                name: 'workOrderDetail',
              },
            } as RcgCardView,
          } as RcgMasterDetailView,
        },
      ],
    } as RcgTabsView,
  ] as RcgView[];
};
