import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import * as Get from '@npm-libs/ng-getx';
import { ActionsComponent, IAction } from '@rcg/actions';
import { GqlInput, QueryInput, RcgPipesModule } from '@rcg/core';
import { FilterSetting, FiltersResult, RcgFiltersComponent } from '@rcg/filters';
import { IntlModule, tr } from '@rcg/intl';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DataCounterService } from '../data-counter/services/data-counter.service';

@Get.NgAutoDispose
@Component({
  selector: 'rcg-data-sum',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    RcgPipesModule,
    ActionsComponent,
    RcgFiltersComponent,
    IntlModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './data-sum.component.html',
  styleUrls: ['./data-sum.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DataCounterService],
})
export class DataSumComponent implements OnInit {
  @Input() gqlInput!: GqlInput | QueryInput;

  @Input() dataCount?: number | undefined;

  @Input() icon?: string;

  @Input() actions?: IAction[] = [];

  @Input() filtersSettings?: FilterSetting = undefined;

  @Output() action = new EventEmitter<IAction>();

  @Output() filterChange = new EventEmitter<FiltersResult>();

  @Output() search = new EventEmitter<string>();

  searchControl = new FormControl('');

  isActionActive = false;

  constructor(public dataCounterService: DataCounterService, private destroyRef: DestroyRef) {}

  searchPlaceholder = toSignal(tr('search'));

  ngOnInit(): void {
    if (this.gqlInput) {
      this.dataCounterService.subscribeToCountData(this.gqlInput);
    }

    if (this.search.observed) {
      this.searchControl.valueChanges
        .pipe(debounceTime(600), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
        .subscribe((value) => {
          this.search.emit(value ?? '');
        });
    }
  }

  onFilterChange(filters: FiltersResult): void {
    this.filterChange.emit(filters);
  }

  onSearch(event: string) {
    this.search.emit(event);
  }

  clearSearch() {
    this.searchControl.setValue('');
    this.search.emit('');
  }
}
