import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, input, OnInit } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AuthService } from '@rcg/auth';
import { GraphqlClientService } from '@rcg/graphql';
import { IntlModule } from '@rcg/intl';
import { catchError, map, of, switchMap } from 'rxjs';
import { customerSummaryQuery } from '../gql/customer-activities.gql';
import { CustomerSummary } from '../models/customer-activities.models';
import { kmViewSettings } from '../settings/km-sales-settings';
import { ncrSettings } from '../settings/ncr-settings';
import { CmpFactoryComponent } from '../views';
import { RcgViewsService } from '../views/views.service';

@Component({
  selector: 'rcg-customer-activities',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, RouterModule, MatIconModule, IntlModule, CmpFactoryComponent],
  styles: [
    `
      :host {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    `,
  ],
  template: `
    @if(summaryData() && viewConfig()) { @for(view of viewConfig(); track view) {
    <rcg-cmp-factory style="height: 100%; width: 100%;" [config]="view" [data]="summaryDataAsRecord()"></rcg-cmp-factory>
    } }
  `,
})
export class CustomerActivitiesComponent implements OnInit {
  private readonly graphClient = inject(GraphqlClientService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly viewsService = inject(RcgViewsService);
  private readonly auth = inject(AuthService);

  organizationId = input<number>();

  viewConfig = toSignal(
    toObservable(this.organizationId).pipe(
      map((id) => {
        if (!id) return null;
        const tenantId = this.auth.tenant()?.id;
        if (!tenantId) return null;
        if (tenantId === 3) {
          return ncrSettings(id);
        } else if ([270, 479, 480].includes(tenantId)) {
          return kmViewSettings(id);
        } else {
          return null;
        }
      }),
    ),
    { initialValue: null },
  );

  private readonly summaryData$ = toObservable(this.organizationId).pipe(
    switchMap((id) => {
      if (!id) return of(null);
      const vars = this.resolveVariables(id);
      return this.graphClient
        .query<{ data: { data: CustomerSummary } }>({
          query: customerSummaryQuery,
          variables: { ...vars },
        })
        .pipe(
          map((result) => result.data?.data),
          catchError((error) => {
            console.error('Error fetching customer summary:', error);
            return of(null);
          }),
        );
    }),
    takeUntilDestroyed(this.destroyRef),
  );

  summaryData = toSignal<CustomerSummary | null>(this.summaryData$, { initialValue: null });

  summaryDataAsRecord() {
    return this.summaryData() as unknown as Record<string, unknown>;
  }

  async ngOnInit() {
    await this.viewsService.initialize();
  }

  private resolveVariables(organizationId: number) {
    const tenant = this.auth.tenant();

    if (!tenant) {
      throw new Error('No tenant available for customer activities resolve variables');
    }

    if (tenant.id === 3) {
      return {
        functionName: 'custom.customer_summary_ncr',
        parameters: { customer_id: organizationId, tenant_id: tenant.id },
      };
    } else if ([270, 479, 480].includes(tenant.id)) {
      return {
        functionName: 'custom.customer_summary_interexport',
        parameters: { customer_id: organizationId, tenant_id: tenant.id },
      };
    } else {
      throw new Error(`Unsupported tenant ID: ${tenant.id}.`);
    }
  }
}
