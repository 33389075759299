import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { RcgCardComponent } from '@rcg/shared';
import { Organization } from '../../models/customer-activities.models';
@Component({
  selector: 'rcg-customer-activities-list-item',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, RouterModule, RcgCardComponent],
  styles: [
    `
      :host {
        height: 100%;
        display: block;
        margin: 1px 2px;
      }
    `,
  ],
  template: `
    @if (data(); as data) {
    <rcg-card [colorBand]="data.children_aggregate?.aggregate?.count ? 'darkviolet' : undefined">
      <div title class="text-ellipsis" style="font-weight: 500; font-size: 14px">
        {{ data.short_name ?? data.long_name ?? '' }}
      </div>
      <div subtitle style="display: flex; justify-content: space-between; font-size: 12px">
        <span class="text-ellipsis">{{ data.street ?? '' }} {{ data.house_number ?? '' }}</span>
        <span style="white-space: nowrap">{{ data.postal_number ?? '' }} {{ data.postal_name ?? '' }}</span>
      </div>
      <div content style="display: flex; justify-content: space-between">
        <span></span>
        <div>{{ data.country?.name ?? '' }}</div>
      </div>
      <div actions>
        <span
          *ngIf="data.parent?.short_name ?? data.parent?.long_name"
          style="font-style: italic; color: orange; font-size: 12px"
          [matTooltip]="data.parent?.long_name ?? data.parent?.short_name"
        >
          {{ data.parent?.short_name ?? data.parent?.long_name }}
        </span>
      </div>
    </rcg-card>
    }
  `,
})
export class CustomerActivitiesListItemComponent {
  data = input<Organization>();
}
