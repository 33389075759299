import { CommonModule } from '@angular/common';
import { Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { breakpointNames, defaultMenuWidths, defaultWidthBreakpoints, ResizableWidth, UserSettingsService } from '@rcg/core';

export type ResizerRole = 'nav' | 'menu';

@Component({
  selector: 'rcg-resizer',
  standalone: true,
  imports: [CommonModule],
  template: '',
  styleUrl: './resizer.component.scss',
})
export class ResizerComponent implements OnInit {
  @Input() resizableWidth = 0;
  @Input() hostElement?: HTMLElement;
  @Input() widthVariableName = '';
  @Input() minWidth = 56;
  @Input() maxWidth = 400;
  @Input() role: ResizerRole = 'nav';

  width = 0;
  defaultWidth = 0;

  grabber = false;
  leftElementWidth = 0;
  mouseXonMouseDown = 0;

  userSettingsService = inject(UserSettingsService);

  menuWidth = toSignal(this.userSettingsService.menuWidth$);
  navWidth = toSignal(this.userSettingsService.navWidth$);

  currentSettings: ResizableWidth = {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
    xxl: 0,
  };

  ngOnInit() {
    if (this.role === 'nav') {
      if (!this.navWidth()?.xs) {
        this.currentSettings = defaultMenuWidths;
        this.currentSettings = this.updateSettingsWidth(this.currentSettings, this.resizableWidth);
        this.userSettingsService.set('navWidth', this.currentSettings);
      } else {
        this.currentSettings = { ...(this.navWidth() ?? defaultMenuWidths) };
      }
    } else if (this.role === 'menu') {
      if (!this.menuWidth()?.xs) {
        this.currentSettings = defaultMenuWidths;
        this.currentSettings = this.updateSettingsWidth(this.currentSettings, this.resizableWidth);
        this.userSettingsService.set('menuWidth', this.currentSettings);
      } else {
        this.currentSettings = { ...(this.menuWidth() ?? defaultMenuWidths) };
      }
    }

    this.defaultWidth = this.getCurrentWidth();
    this.width = this.defaultWidth;

    this.hostElement?.style.setProperty(this.widthVariableName, `${this.width}px`);
  }

  updateSettingsWidth(settings: ResizableWidth, width: number) {
    const screenWidth = window.innerWidth;

    for (const key of breakpointNames) {
      if (screenWidth <= defaultWidthBreakpoints[key as keyof ResizableWidth]) {
        settings[key as keyof ResizableWidth] = width;
        break;
      }
    }

    return settings;
  }

  getCurrentWidth() {
    const screenWidth = window.innerWidth;

    for (const key of breakpointNames) {
      if (screenWidth <= defaultWidthBreakpoints[key as keyof ResizableWidth]) {
        return this.currentSettings[key as keyof ResizableWidth];
      }
    }

    return 300;
  }

  updateUserSetting() {
    this.currentSettings = this.updateSettingsWidth(this.currentSettings, this.width);

    this.userSettingsService.set(this.role === 'nav' ? 'navWidth' : 'menuWidth', this.currentSettings);
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    this.grabber = true;
    this.mouseXonMouseDown = event.clientX;
    document.body.style.cursor = 'e-resize';
  }

  @HostListener('window:mouseup') onMouseUp() {
    this.grabber = false;

    if (this.defaultWidth !== this.width) {
      this.updateUserSetting();
    }

    document.body.style.cursor = 'default';
    this.defaultWidth = this.width;
  }

  @HostListener('window:mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    if (this.grabber) {
      if (this.hostElement) {
        event.preventDefault();
        this.width = this.defaultWidth + event.clientX - this.mouseXonMouseDown;
        this.width = Math.min(this.width, this.maxWidth);
        this.width = Math.max(this.width, this.minWidth);
        this.hostElement.style.setProperty(this.widthVariableName, `${this.width}px`);
      }
    }
  }

  @HostListener('window:resize') onResize() {
    const w = this.getCurrentWidth();

    if (w !== this.width) {
      this.width = w;
      this.defaultWidth = w;

      this.hostElement?.style.setProperty(this.widthVariableName, `${this.width}px`);
    }
  }
}
