import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { Favorite } from '@rcg/filters';
import { ResizerComponent } from '../resizer/resizer.component';
import { FavoritesMenuPosition, NavMenuItem } from '../side-nav/models/nav-menu-item.model';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { ModuleLayoutDetailCloseButtonComponent } from './detail-close-button/detail-close-button.component';
import { ElementReferenced, fromElement } from './element-referenced.decorator';

const selector = 'rcg-module-layout';

function isWindowVertical(): boolean {
  return window.innerWidth / window.innerHeight <= 1;
}

@ElementReferenced
@Component({
  selector,
  templateUrl: './module-layout.component.html',
  styleUrls: ['./module-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SideNavComponent, MatDividerModule, ResizerComponent],
})
export class ModuleLayoutComponent {
  @ViewChild('resizableElement', { read: ElementRef }) resizableElementRef?: ElementRef;

  @Input() menuId?: string;
  @Input() navMenuItems: NavMenuItem[] = [];
  @Input() favorites?: Favorite[] = [];
  @Input() favoritesMenuPosition?: FavoritesMenuPosition = 'top';

  @Input() routable = true;
  @Input() menuAlwaysExpanded = false;
  @Input() menuItemsExpanded = false;
  @Input() childMenuItemsExpanded = true;
  @Input() showZeroCount = true;

  @HostBinding('class.detail-selected') @Input() detailSelected = false;
  @HostBinding('class.detail-only') @Input() detailOnly = false;

  @HostBinding('class.stacked-layout') stacked = isWindowVertical();

  @Output() navMenuItemClicked = new EventEmitter<NavMenuItem>();
  @Output() selectedFavorite = new EventEmitter<Favorite>();

  constructor(public el: ElementRef) {}

  onNavMenuItemClicked(navItem: NavMenuItem): void {
    this.navMenuItemClicked.emit(navItem);
  }

  get visible() {
    return this.stacked;
  }

  @HostListener('window:resize')
  updateCloseBtn() {
    this.stacked = isWindowVertical();
    ModuleLayoutDetailCloseButtonComponent.setButtonsVisible(this.el.nativeElement, this.visible);
  }

  static closest(childEl: Element) {
    const el = childEl.closest(selector);
    if (!el) return null;

    return fromElement(ModuleLayoutComponent, el);
  }
}
