import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { UserSettingsService } from '@rcg/core';
import { Favorite } from '@rcg/filters';
import { IntlModule } from '@rcg/intl';
import { MessageService } from '@rcg/standalone';
import { ResizerComponent } from '../resizer/resizer.component';
import { FavoritesMenuPosition, NavMenuItem } from './models/nav-menu-item.model';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
interface StorageMenuSettings {
  isExpanded: boolean;
}

interface AllMenuSettings {
  [menuId: string]: StorageMenuSettings;
}

@Component({
  selector: 'rcg-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    RouterModule,
    NavMenuComponent,
    MatTooltipModule,
    IntlModule,
    ResizerComponent,
  ],
})
export class SideNavComponent implements OnInit {
  @Input() menuId?: string | undefined;

  @Input() navMenuItems: NavMenuItem[] = [];

  @Input() favorites?: Favorite[] = [];

  @Input() favoritesMenuPosition?: FavoritesMenuPosition = 'top';

  @Input() initialCategory?: NavMenuItem | null | undefined;

  @Input() categoryTitle = '';

  @Input() routable = true;

  @Input() menuAlwaysExpanded = false;

  @Input() menuItemsExpanded = false;

  @Input() childMenuItemsExpanded = true;

  @Input() showZeroCount = true;

  @Input() expandedIcon = 'more_horiz';

  @Output() navMenuItemClicked = new EventEmitter<NavMenuItem>();

  @Output() selectedFavorite = new EventEmitter<Favorite>();

  private readonly messageService = inject(MessageService);

  isExpanded = signal(false);
  storageSettings = signal<StorageMenuSettings | null>(null);

  private readonly MENU_SETTINGS_KEY = 'menuSettings';

  @ViewChild('leftElement', { read: ElementRef }) leftElementHTMLElement?: ElementRef;
  @ViewChild('rightElement', { read: ElementRef }) rightElementHTMLElement?: ElementRef;

  constructor(public el: ElementRef<HTMLElement>, private readonly userSettingsService: UserSettingsService) {}

  menuWidth = toSignal(this.userSettingsService.menuWidth$);
  navWidth = toSignal(this.userSettingsService.navWidth$);

  ngOnInit(): void {
    if (this.menuAlwaysExpanded) {
      this.isExpanded.set(true);
      return;
    }

    if (this.menuItemsExpanded) {
      this.isExpanded.set(true);
    }

    if (!this.menuId) {
      this.menuId = 'app_menu';
    }

    const storageSettings = this.getMenuSettings();
    if (storageSettings !== null) {
      this.storageSettings.set(storageSettings);
      if (storageSettings.isExpanded != null) {
        this.isExpanded.set(storageSettings.isExpanded);
      }
    }
  }

  onNavMenuItemClicked(navItem: NavMenuItem): void {
    this.navMenuItemClicked.emit(navItem);
  }

  toggleMenuItemsExpanded(): void {
    const storageExpanded = this.storageSettings()?.isExpanded ?? false;
    this.saveMenuSettings(!storageExpanded);
  }

  toggleExpanded() {
    this.isExpanded.set(!this.isExpanded());
  }

  private getMenuSettings(): StorageMenuSettings | null {
    try {
      const allSettings = localStorage.getItem(this.MENU_SETTINGS_KEY);
      if (!allSettings) return null;

      const settings: AllMenuSettings = JSON.parse(allSettings);
      return settings[this.menuId!] ?? null;
    } catch (error) {
      console.error(`Failed to load menu settings  ${this.menuId} from localStorage`, error);
      return null;
    }
  }

  private saveMenuSettings(isExpanded: boolean): void {
    try {
      let allSettings: AllMenuSettings = {};
      const existingSettings = localStorage.getItem(this.MENU_SETTINGS_KEY);

      if (existingSettings) {
        allSettings = JSON.parse(existingSettings);
      }

      allSettings[this.menuId!] = { isExpanded };
      localStorage.setItem(this.MENU_SETTINGS_KEY, JSON.stringify(allSettings));

      this.isExpanded.set(isExpanded);
      this.storageSettings.set({ isExpanded });
    } catch (error) {
      this.messageService.showErrorSnackbar(
        `Failed to save menu settings ${this.menuId} to localStorage`,
        (error as Error)?.message ?? error,
      );
    }
  }
}
