import { QueryInput } from '@rcg/core';
import { RcgCardView, RcgMasterDetailView, RcgQueryListView, RcgTabsView, RcgView } from '../../../models';

export const kmViewSettings = (customerId: number) => {
  return [
    {
      type: 'tabs',
      name: 'tabs',
      align: 'start',
      strech: false,
      tabs: [
        {
          type: 'card',
          name: 'customerSummary',
          translation: 'summary',
          description: 'summary',
          icon: 'info',
          view: {
            type: 'card',
            name: 'customerSummary',
          },
        } as RcgCardView,
        {
          supportedGroups: [384, 1185, 1244, 1245, 1267, 1180, 1248, 1253, 1268, 1178, 1252, 1187, 1250, 1269, 1179],
          icon: 'event',
          translation: 'activities',
          description: 'activities',
          view: {
            type: 'master-detail',
            name: 'activities',
            list: {
              searchable: true,
              itemSize: 90,
              type: 'query-list',
              name: 'activities',
              componentName: 'salesListItem',
              query: {
                type: 'hasura-query',
                alias: 'data',
                countAll: {
                  enabled: true,
                },
                count: true,
                fields: [
                  'id',
                  'form_id',
                  'created_at',
                  'updated_at',
                  'short_description:title',
                  'report',
                  'rruleset',
                  {
                    status: ['id', 'description:name_translation', 'color'],
                  },
                  {
                    'user:owner': ['id', 'full_name'],
                  },
                  {
                    customer: ['id', 'short_name'],
                  },
                  {
                    contact: ['id', 'full_name'],
                  },
                ],
                operation: 'calendar_search_events',
                variables: {
                  args: {
                    type: 'calendar_search_events_args!',
                    value: {
                      p_extra: null,
                      p_search: '$search',
                    },
                  },
                  limit: {
                    value: 15,
                  },
                  where: {
                    type: 'calendar_events_bool_exp',
                    value: [
                      {
                        condition: 'and',
                        predicates: [
                          {
                            field: 'tenant_id',
                            value: '$tenantId',
                            operator: 'eq',
                          },
                          {
                            field: 'created_in_view_id',
                            value: 257,
                            operator: 'eq',
                          },
                          {
                            field: 'customer_id',
                            value: customerId,
                            operator: 'eq',
                          },
                        ],
                      },
                    ],
                  },
                  offset: {
                    value: 0,
                  },
                  order_by: {
                    type: '[calendar_events_order_by!]',
                    value: [
                      {
                        field: 'updated_at',
                        direction: 'descending',
                      },
                    ],
                  },
                },
              } as QueryInput,
            } as RcgQueryListView,
            detail: {
              type: 'card',
              name: 'activityDetail',
              view: {
                type: 'card',
                name: 'activityDetail',
              },
            } as RcgCardView,
          } as RcgMasterDetailView,
        },
        {
          supportedGroups: [384, 1185, 1244, 1245, 1267, 1180, 1248, 1253, 1268, 1178, 1252, 1187, 1250, 1269, 1179],
          translation: 'opportunities',
          description: 'opportunities',
          icon: 'store',
          view: {
            type: 'master-detail',
            name: 'opportunities',
            list: {
              type: 'query-list',
              name: 'opportunities',
              searchable: true,
              itemSize: 100,
              componentName: 'salesListItem',
              query: {
                type: 'hasura-query',
                alias: 'data',
                countAll: {
                  enabled: true,
                },
                count: true,
                fields: [
                  'id',
                  'form_id',
                  'created_at',
                  'updated_at',
                  'short_description',
                  {
                    status: ['id', 'description', 'color'],
                  },
                  {
                    opportunity_resource: ['description_ml'],
                  },
                  {
                    user: ['id', 'full_name'],
                  },
                  {
                    customer: ['id', 'short_name'],
                  },
                  {
                    organization: ['id', 'short_name'],
                  },
                  {
                    contact: ['id', 'full_name'],
                  },
                ],
                operation: 'sales_search_opportunity',
                variables: {
                  args: {
                    type: 'sales_search_opportunity_args!',
                    value: {
                      search: '$search',
                    },
                  },
                  limit: {
                    value: 15,
                  },
                  where: {
                    type: 'sales_opportunity_bool_exp',
                    value: [
                      {
                        condition: 'and',
                        predicates: [
                          {
                            field: 'tenant_id',
                            value: '$tenantId',
                            operator: 'eq',
                          },
                          {
                            field: 'customer_id',
                            value: customerId,
                            operator: 'eq',
                          },
                        ],
                      },
                    ],
                  },
                  offset: {
                    value: 0,
                  },
                  order_by: {
                    type: '[sales_opportunity_order_by!]',
                    value: [
                      {
                        field: 'updated_at',
                        direction: 'descending',
                      },
                    ],
                  },
                },
              } as QueryInput,
            } as RcgQueryListView,
            detail: {
              type: 'card',
              name: 'salesDetail',
              view: {
                type: 'card',
                name: 'salesDetail',
              },
            } as RcgCardView,
          } as RcgMasterDetailView,
        },
        {
          supportedGroups: [1293, 1185, 1245, 1183, 1281, 384, 1227, 1228, 1267, 1229, 1184, 1244],
          translation: 'phone_calls',
          description: 'Direct calls',
          icon: 'phone',
          view: {
            type: 'master-detail',
            name: 'direct_calls',
            list: {
              type: 'query-list',
              name: 'direct_calls',
              componentName: 'directCall',
              searchable: true,
              query: {
                type: 'hasura-query',
                alias: 'data',
                countAll: {
                  enabled: true,
                },
                count: true,
                operation: 'telephony_search_o365_dr_calls',
                variables: {
                  args: {
                    type: 'telephony_search_o365_dr_calls_args!',
                    value: {
                      p_search: '$search',
                    },
                  },
                  limit: {
                    value: 20,
                  },
                  where: {
                    type: 'telephony_o365_dr_calls_bool_exp',
                    value: [
                      {
                        condition: 'and',
                        predicates: [
                          {
                            field: 'organization_id',
                            value: '$organizationId',
                            operator: 'eq',
                          },
                          {
                            field: 'contact.employer_id',
                            value: customerId,
                            operator: 'eq',
                          },
                          {
                            field: 'agent',
                            condition: 'and',
                            predicates: [
                              {
                                field: 'tenant_group_members',
                                condition: 'and',
                                predicates: [
                                  {
                                    field: 'group_id',
                                    value: [1183, 1184],
                                    operator: 'in',
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            condition: 'not',
                            predicates: [
                              {
                                field: 'agent',
                                condition: 'and',
                                predicates: [
                                  {
                                    field: 'tenant_group_members',
                                    condition: 'and',
                                    predicates: [
                                      {
                                        field: 'group_id',
                                        value: [1244],
                                        operator: 'in',
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  offset: {
                    value: 0,
                  },
                  order_by: {
                    type: '[telephony_o365_dr_calls_order_by!]',
                    value: [
                      {
                        field: 'invite_date',
                        direction: 'descending',
                      },
                    ],
                  },
                },
                fields: [
                  'id',
                  'created_at',
                  'updated_at',
                  'call_id',
                  'call_end_subreason',
                  'call_type',
                  'callee_number',
                  'caller_number',
                  'correlation_id',
                  'duration',
                  'end_date',
                  'failure_date',
                  'final_sipcode_phrase',
                  'final_sipcode',
                  'invite_date',
                  'media_bypass_enabled',
                  'media_path_location',
                  'signaling_location',
                  'start_date',
                  'successful_call',
                  'trunk_fully_qualified_domain_name',
                  'user_display_name',
                  'user_id',
                  'user_principalname',
                  'tenant_id',
                  'organization_id',
                  'agent_id',
                  'agent_name',
                  'contact_id',
                  'contact_name',
                  'no_of_missed_calls',
                  'contact_phone_type',
                  {
                    contact: ['id', 'employer_id'],
                  },
                ],
              },
            } as RcgQueryListView,
            detail: {
              type: 'card',
              name: 'directCallDetail',
              view: {
                type: 'card',
                name: 'directCallDetail',
              },
            } as RcgCardView,
          } as RcgMasterDetailView,
        },
        {
          translation: 'device_pl',
          description: 'Devices',
          icon: 'devices',
          view: {
            type: 'master-detail',
            name: 'devices',
            list: {
              type: 'query-list',
              name: 'deviceListItem',
              componentName: 'deviceListItem',
              searchable: true,
              query: {
                type: 'hasura-query',
                alias: 'data',
                count: true,
                countAll: {
                  enabled: true,
                },
                fields: [
                  'id',
                  'tenant_id',
                  'organization_id',
                  'data',
                  'is_active',
                  'date_of_instalation',
                  'warranty_until',
                  'product_id',
                  'serial_no',
                  'description',
                  'updated_at',
                  {
                    customer: ['id', 'short_name'],
                  },
                  'form_id',
                ],
                operation: 'custom_installed_equipment_search_1',
                variables: {
                  args: {
                    type: 'custom_installed_equipment_search_1_args!',
                    value: {
                      p_search: '$search',
                      p_organization_id: '$organizationId',
                    },
                  },
                  limit: {
                    value: 20,
                  },
                  where: {
                    type: 'custom_installed_equipment_bool_exp',
                    value: [
                      {
                        condition: 'and',
                        predicates: [
                          {
                            field: 'tenant_id',
                            value: '$tenantId',
                            operator: 'eq',
                          },
                          {
                            field: 'customer.id',
                            value: customerId,
                            operator: 'eq',
                          },
                        ],
                      },
                    ],
                  },
                  offset: {
                    value: 0,
                  },
                  order_by: {
                    type: '[custom_installed_equipment_order_by!]',
                    value: [
                      {
                        field: 'updated_at',
                        direction: 'descending',
                      },
                    ],
                  },
                },
              },
            } as RcgQueryListView,
            detail: {
              type: 'form',
              name: 'form',
              formId: 758,
              formMode: 'update',
            },
          } as RcgMasterDetailView,
        },
        {
          supportedGroups: [
            384, 1183, 1184, 1227, 1228, 1229, 1244, 1267, 1281, 1293, 1279, 1295, 1181, 1257, 1268, 1178, 1294, 1280, 1186, 1269, 1179,
            1259,
          ],
          translation: 'service_orders',
          description: 'Work Orders',
          icon: 'build',
          view: {
            type: 'master-detail',
            name: 'work_orders',
            list: {
              type: 'query-list',
              name: 'workOrderListItem',
              componentName: 'workOrderListItem',
              searchable: true,
              query: {
                type: 'hasura-query',
                alias: 'data',
                countAll: {
                  enabled: true,
                },
                count: true,
                fields: [
                  'id',
                  'form_id',
                  'created_at',
                  'updated_at',
                  'short_description',
                  'external_number',
                  'data',
                  'subscription_capable',
                  'process_definition_id',
                  'process_definition_matrix_id',
                  'owner_id',
                  'has_communication',
                  'color',
                  'rating',
                  'bg_color',
                  'has_attachments',
                  {
                    project: ['id', 'short_description'],
                  },
                  {
                    owner: ['id', 'full_name', 'username'],
                  },
                  {
                    contact: ['id', 'full_name', 'email'],
                  },
                  {
                    customer: ['id', 'short_name'],
                  },
                  {
                    organization: ['id', 'short_name'],
                  },
                  {
                    status: ['id', 'color', 'description'],
                  },
                  {
                    priority: ['id', 'description', 'icon', 'order_index'],
                  },
                ],
                operation: 'servicedesk_search_helpdesk',
                variables: {
                  args: {
                    type: 'servicedesk_search_helpdesk_args!',
                    value: {
                      org_id: '$organizationId',
                      search: '$search',
                    },
                  },
                  limit: {
                    value: 20,
                  },
                  where: {
                    type: 'servicedesk_helpdesk_bool_exp',
                    value: [
                      {
                        condition: 'and',
                        predicates: [
                          {
                            field: 'tenant_id',
                            value: '$tenantId',
                            operator: 'eq',
                          },
                          {
                            field: 'customer.id',
                            value: customerId,
                            operator: 'eq',
                          },
                        ],
                      },
                    ],
                  },
                  offset: {
                    value: 0,
                  },
                  order_by: {
                    type: '[servicedesk_helpdesk_order_by!]',
                    value: [
                      {
                        field: 'updated_at',
                        direction: 'descending',
                      },
                    ],
                  },
                },
              } as QueryInput,
            } as RcgQueryListView,
            detail: {
              type: 'card',
              name: 'workOrderDetail',
              view: {
                type: 'card',
                name: 'workOrderDetail',
              },
            } as RcgCardView,
          } as RcgMasterDetailView,
        },
      ],
    } as RcgTabsView,
  ] as RcgView[];
};
