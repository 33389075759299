<rcg-side-nav
  class="sidenav"
  [menuId]="menuId"
  [navMenuItems]="navMenuItems"
  [routable]="routable"
  [menuAlwaysExpanded]="menuAlwaysExpanded"
  [menuItemsExpanded]="menuItemsExpanded"
  [childMenuItemsExpanded]="childMenuItemsExpanded"
  [favorites]="favorites"
  [showZeroCount]="showZeroCount"
  [favoritesMenuPosition]="favoritesMenuPosition"
  (navMenuItemClicked)="onNavMenuItemClicked($event)"
  (selectedFavorite)="selectedFavorite.emit($event)"
>
  <nav #resizableElement style="position: relative">
    <ng-content select="[rcgModuleNav]"></ng-content>
    @if (resizableElementRef) {
    <rcg-resizer
      [resizableWidth]="resizableElementRef.nativeElement.clientWidth"
      [minWidth]="200"
      [maxWidth]="600"
      [hostElement]="el.nativeElement"
      [widthVariableName]="'--nav-width'"
      [role]="'nav'"
    ></rcg-resizer>
    }
  </nav>

  <mat-divider class="divider" [vertical]="true"></mat-divider>

  <main>
    <ng-content select="[rcgModuleDetail]"></ng-content>
  </main>
</rcg-side-nav>
